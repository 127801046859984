:root {
  --color-black: hsl(0, 0%, 10%);
  --color-white: hsl(0, 0%, 100%);
  --color-darken: hsl(0, 0%, 20%);
  --color-pink-100: hsl(336, 95%, 94%);
  --color-pink-200: hsl(338, 91%, 87%);
  --color-pink-300: hsl(339, 90%, 81%);
  --color-pink-400: hsl(339, 88%, 74%);
  --color-pink-500: hsl(339, 82%, 67%);
  --color-pink-600: hsl(339, 76%, 59%);
  --color-pink-700: hsl(339, 67%, 52%);
  --color-pink-800: hsl(339, 68%, 45%);
  --color-pink-900: hsl(339, 69%, 38%);
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 100%;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}

body {
  overflow-x: hidden;
  background: #0E0C15;
 font-family: "Montserrat Alternates", sans-serif;
  color: #bcbcbc;
  font-weight: 500;
  scroll-padding-top: 130px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
 font-family: "Montserrat Alternates", sans-serif;
  color: #fff;
  font-weight:600;
}
a,
button {
 font-family: "Montserrat Alternates", sans-serif;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  transition: 0.3s;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
section {
  overflow: hidden;
}
img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
ul {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  transition: 0.3s;
}
a:hover {
  transition: 0.3s;
}
.container-fluid {
  padding-left: 120px;
  padding-right: 120px;
}

.header {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0.75rem 0;
  z-index: 100;
  background-color: #000000;
  -webkit-box-shadow: var(--shadow-medium);
  box-shadow: var(--shadow-medium);
}
.header-item-left .brand img {
  width: 66px;
}
.header .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-item-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12%;
  flex: 0 0 12%;
}
.header-item-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 1;
  flex: 1;
  justify-content: end;
}
.header-item-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.header-item-right .menu-icon {
  font-size: 1.35rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1rem;
  margin-left: 1rem;
  color: var(--color-black);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header .menu > ul > li {
  display: inline-block;
  line-height: 3.125rem;
  margin-left: 4.5rem;
}
.header .menu > ul > li > a {
  position: relative;
  font-size: 18px;
  line-height: 1.25;
  border: none;
  outline: none;
  color: #ffffff;
  text-transform: capitalize;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.header .menu > ul > li > a.active-x{
  color: #B30003;
  font-weight: 800;
}
.header .menu > ul > li .menu-subs {
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 1.75rem;
  padding: 1rem 2rem;
  z-index: 109;
  opacity: 0;
  visibility: hidden;
  border-radius: 0.25rem;
  border-top: 3px solid hsl(0deg 0% 42%);
  background: hsl(345deg 7% 13%);
  -webkit-box-shadow: var(--shadow-medium);
  box-shadow: var(--shadow-medium);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header .menu > ul > li .menu-subs > ul > li {
  line-height: 1;
}
.header .menu > ul > li .menu-subs > ul > li > a {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: #bcbcbc;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header .menu > ul > li .menu-column-1 {
  min-width: 16rem;
  max-width: 20rem;
}
.header .menu > ul > li .menu-subs.menu-mega {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li {
  display: block;
  line-height: 1;
}
.header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 0.5rem 0;
  border: none;
  outline: none;
  color: var(--color-black);
  text-transform: capitalize;
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.header .menu > ul > li .menu-subs.menu-column-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 68rem;
  width: 100%;
  padding: 1.25rem 1rem;
}
.header .menu > ul > li .menu-subs.menu-column-4 > .list-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  padding: 0 1rem;
}
.header .menu > ul > li .menu-subs.menu-column-4 > .list-item .title {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.75rem 0;
  color: var(--color-pink-700);
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header
  .menu
  > ul
  > li
  .menu-subs.menu-column-4
  > .list-item.text-center
  .title {
  text-align: center;
}
.header .menu > ul > li .menu-subs.menu-column-4 > .list-item img {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 0.75rem;
  -o-object-fit: cover;
  object-fit: cover;
}
.header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a:hover,
.header .menu > ul > li .menu-subs > ul > li > a:hover {
  color: #B30003;
  font-weight: 700;
  transition: 0.5s;
}
.header-item-right a:hover,
.header .menu > ul > li:hover > a {
  color: #B30003;
  font-weight: 700;
  transition: 0.5s;
}
.header .menu-mobile-header,
.header .menu-mobile-toggle {
  display: none;
}
.Categoriessss svg {
  margin-left: 4px;
}
.header .menu > ul > li svg path {
  transition: 0.3s;
  stroke: #B30003;
}
.header .menu > ul > li:hover svg path {
  stroke: #B30003;
  transition: 0.3s;
}
.main-icon-form-media {
  display: flex;
  align-items: center;
}
.wsdd form {
  position: relative;
}
.wsdd ul li {
  display: inline-block;
  position: relative;
}
.wsdd ul li a {
  display: block;
  color: #B30003;
  position: relative;
  font-size: 18px;
  padding: 11px 20px;
  border-radius: 30px;
  background-color: #fff;
  font-weight: 700;
  transition: 0.5s;
}
.wsdd ul li a:hover {
  color: #fff;
  background-color: #B30003;
  transition: 0.5s;
}
.wsdd ul .Loginss:after {
  content: "/";
  position: absolute;
  right: 7px;
  top: 1px;
  color: #b3b3b3;
}
.activee {
  color: #8c0251 !important;
}
.wsdd.qaaa ul {
  display: flex;
}
.wsdd.qaaa ul li a {
  margin-right: 6px;
}
.wsdd.qaaa svg {
  width: 32px;
}
.wsdd.qaaa svg path {
  transition: 0.3s;
}
.wsdd.qaaa svg:hover circle {
  stroke: #8c0251;
  transition: 0.3s;
}
@media screen and (min-width: 993px) {
  .header .menu > ul > li.menu-item-has-children:hover .menu-subs {
    margin-top: 0.5rem;
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 1024px) {
  .header-item-center {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .header-item-left,
  .header-item-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .header-item-left {
    flex: 1;
}
  .wrapper {
    margin: 0 auto;
    padding: 0.5rem 0;
    width: 100%;
}

  .header .menu-mobile-toggle {
    position: relative;
    display: block;
    cursor: pointer;
    width: 1.75rem;
    height: 1rem;
    border: none;
    outline: none;
    margin-left: 1.25rem;
    margin-top: -0.25rem;
    background: none;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
  }
  .header .menu-mobile-toggle span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    border: none;
    outline: none;
    opacity: 1;
    border-radius: 0.25rem;
    background: hsl(60deg 100% 97%);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .header .menu-mobile-toggle span:nth-child(1) {
    top: 0;
  }
  .header .menu-mobile-toggle span:nth-child(2),
  .header .menu-mobile-toggle span:nth-child(3) {
    top: 0.5rem;
  }
  .header .menu-mobile-toggle span:nth-child(4) {
    top: 1rem;
  }
  .header-item-right {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    z-index: 111;
    overflow: hidden;
    background: #000;
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .header .menu.active {
    -webkit-transform: translate(0%);
    transform: translate(0%);
  }
  .header .menu > ul > li {
    display: block;
    line-height: 1;
    margin: 0;
  }
  .header .menu > ul > li > a {
    display: block;
    line-height: 4.125rem;
    height: 4.125rem;
    padding: 0 3.125rem 0 3rem;
    border-bottom: 1px solid rgb(24 24 24);
  }
  .header .menu > ul > li > a i.ion {
    position: absolute;
    font-size: 1.25rem;
    line-height: 3.125rem;
    top: 0;
    right: 0;
    width: 3.125rem;
    height: 3.125rem;
    text-align: center;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .header .menu .menu-mobile-header {
    position: relative;
    position: sticky;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    top: 0;
    height: 3.125rem;
    z-index: 110;
    border-bottom: 1px solid rgb(24 24 24);
    background: #000;
  }
  .header .menu .menu-mobile-header .menu-mobile-arrow {
    font-size: 1.25rem;
    line-height: 3.125rem;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
    text-align: center;
    border-right: 1px solid rgb(24 24 24);
    color: #8c0251;
  }
  .header .menu .menu-mobile-header.active .menu-mobile-arrow {
    display: block;
  }
  .header .menu .menu-mobile-header .menu-mobile-title {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: inherit;
    color: var(--color-black);
    text-transform: capitalize;
  }
  .header .menu .menu-mobile-header .menu-mobile-close {
    font-size: 2.25rem;
    line-height: 0.125rem;
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    text-align: center;
    border-left: 1px solid rgb(24 24 24);
    color: #B30003;
  }
  .header .menu .menu-section {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .header .menu > ul > li .menu-subs {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0rem;
    padding-top: 3rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .header .menu > ul > li .menu-subs.menu-mega {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header .menu > ul > li .menu-subs.active {
    display: block;
  }
  .header .menu > ul > li .menu-subs.menu-column-4 > .list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 0rem;
  }
  .header .menu > ul > li .menu-subs.menu-column-4 > .list-item img {
    margin-top: 0;
  }
  .header
    .menu
    > ul
    > li
    .menu-subs.menu-column-4
    > .list-item.text-center
    .title {
    margin-bottom: 1.25rem;
  }
  .header
    .menu
    > ul
    > li
    .menu-subs.menu-column-4
    > .list-item.text-center:last-child
    .title {
    margin-bottom: 0rem;
  }
  .header .menu > ul > li .menu-subs > ul > li > a {
    display: block;
  }
  .header .menu > ul > li .menu-subs.menu-mega > .list-item > ul {
    margin-bottom: 1rem;
  }
  .header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a {
    display: block;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
  }
  .overlay.active {
    opacity: 1;
    visibility: visible;
  }
}
.social ul {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px 4.5rem;
}
.social ul li a {
  border: 1px solid #fff;
  padding: 0px;
  display: block;
  border-radius: 45px;
  width: 36px;
  text-align: center;
  height: 36px;
  line-height: 42px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social ul li a:hover {
  border: 1px solid #B30003;
  background-color: #B30003;
  transition: 0.3s;
}
.social ul li a svg path {
  fill: #fff;
}
.social ul li a svg {
  width: 21px;
  height: 22px;
}
.bux img {
  position: absolute;
  top: 89px;
  right: 0;
  z-index: -1;
  opacity: 0.8;
  width: 58%;
}
.bux2 {
  position: absolute;
  top: 89px;
  left: 0;
  z-index: 0;
  opacity: 01;
  width: 1110px;
}
body > .skiptranslate {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
@media print {
  #google_translate_element {
    display: none;
  }
}
.goog-logo-link {
  display:none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  margin: 0;
  position: relative;
  top: 9px;
  right: 23px;
  width: 87px;
  padding: 11px 7px;
  border-radius: 6px;
  background-color: #000;
  border: 0;
  color:#fff;
}
.goog-te-gadget .goog-te-combo:focus {
  outline: none;
}
#google_translate_element {
  color: transparent;
}
#google_translate_element a {
  display: none;
}
.google-translate-select {
  /* Change background color */
  background-color: #000 !important;
  /* Change text color */
  color: #333;
  /* Add padding */
  padding: 8px;
  /* Change border */
  border: 1px solid #ccc;
  /* Change font */
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  /* Adjust width */
  /* Add some rounded corners */
  border-radius: 5px;
  /* Customize the dropdown arrow */
  /* background-image: url('../images/arrow.png'); */
  background-repeat: no-repeat;
  background-position: right center;
  /* Ensure the dropdown arrow is visible */
  padding-right: 20px;
}

/* Style the dropdown options */
.google-translate-select option {
  /* Change text color */
  color: #333;
  /* Change background color */
  background-color: #fff;
  /* Add padding */
  padding: 8px;
  /* Adjust font */
  font-family: Arial, sans-serif;
}
.goog-te-combo option:first-child {
  display: none !important;
}
.box-dc h3 span {
  display: flex;
  align-items: center;
}
.goog-te-banner-frame, .goog-logo-link { display: none }

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget::selection { color: transparent !important; }

.goog-te-gadget::-moz-selection { color: transparent !important; }

#google_translate_element img {
  width: 24px; /* Adjust size as needed */
  height: auto;
  cursor: pointer;
}
/*--- banner ---*/

.banner {
  position: relative;
  overflow: hidden;
}
.Anatomy {
  margin-top: 6em;
}
.Anatomy img {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.box-dc{
  background-image: url("./images/frame.png");
    background-repeat: no-repeat;
    display: block;
    text-align: center;
    background-size: 100% 100%;
    padding: 30px;
}
.vb_xss {
  margin: 80px 0 0px 0;
  position: relative;
  z-index: 1;
}
.solna img {
  width: 72px;
  border:3px solid #ffff;
  border-radius: 60px;
  margin: 0 auto;
}
.solna {
  position: relative;
  margin-top: -66px;
}
.box-dc h1 {
  font-size: 28px;
  line-height: 40px;
  margin-top: 22px;
}
.box-dc h1 span {
  color: #b30003;
}
.meme-power {
  background-color: #000000;
  padding: 16px 6px;
  width: 80%;
  margin: 12px auto;
  transform-box: fill-box;
  transform: rotate(359deg);
}
.meme-power h2 {
  font-size: 20px;
  transform-box: fill-box;
  transform-origin: top;
  transform: rotate(1deg);
  margin: 0;
}
.fox h4 {
    font-size: 18px;
    text-align: left;
}
.box-dc h3 {
  font-size: 20px;
  margin: 23px 0;
  display: flex;
  gap: 6px;
  justify-content: center;
}

.fox {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 67%;
  margin: 0 auto;
}
.fox-x input {
  padding: 7px 50px 7px 17px;
  border-radius: 13px;
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  border: 0;
}
.fox h4 {
  font-size: 18px;
  text-align: left;
}
.nmc {
  position: relative;
}
.nmc img {
  width: 32px;
  position: absolute;
  right: 10px;
  top: 16%;
}
::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
  position: relative;
  top: 1px;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color:#000;
  top: 1px;
  position: relative;
}
textarea:focus, input:focus{
  outline: none;
}
.copy-text {
  position: relative;
  padding: 0px 5px;
  background: #868891;
  border-radius: 100px;
  display: flex;
  justify-content: center;
}
.copy-text input.text {
  padding: 7px 62px 7px 7px;
  font-size: 15px;
  color: #000;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 100px;
  background-color: transparent;
  font-weight: 700;
}
.copy-text button {
  padding: 2px 15px;
  background: #ffffff;
  color: #000;
  font-size: 15px;
  border: none;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  font-weight: 800;
  border: 1px solid #000;
  right: 5px;
  top: 3px;
}


.copy-text button:active {
	background: #B30003;
}
.copy-text button:before {
	content: "Copied";
	position: absolute;
	top: -50px;
    right: 0px;
    background: green;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 13px;
    display: none;
    color: rgb(255 255 255 / 88%);
    font-weight: 600;
}
.copy-text button:after {
	content: "";
	position: absolute;
	top: -20px;
	right: 25px;
	width: 10px;
	height: 10px;
	background: green;
	transform: rotate(45deg);
	display: none;
}
.copy-text.active button:before,
.copy-text.active button:after {
	display: block;
}
.cop-x h4 {
  font-size: 18px;
  margin: 0 0 10px 0;
}
.cop-x {
  margin: 34px 28px 20px 28px;
}
.buy-x {
  background-color: #fff;
  border-radius: 30px;
  font-weight: 700;
  padding: 14px;
  width: 72%;
  transition: 0.5s;
  font-size: 19px;
}
.buy-x:hover{
  background-color: #D22204;
  transition: 0.5s;
  color: #fff;
}
.box-dc p {
  color: #fff;
  font-size: 16px;
}
.chain .modal-content {
  background: -webkit-linear-gradient(-45deg, #86104b 0, #090979 100%);
  background: -moz-linear-gradient(135deg, #86104b 0, #090979 100%);
  background: linear-gradient(135deg, #86104b 0, #090979 100%);
}
.chain ul li a img {
  width: 25px;
  height: 25px;
}
.chain ul li a {
  display: flex;
  align-items: center;
  gap: 9px;
  background-color:rgb(255 255 255 / 31%);
  margin-bottom: 11px;
  padding: 11px 17px;
  border-radius: 7px;
  color: #fff;
}
.chain ul li a:hover {
  background-color: rgb(255 0 135 / 58%);
}
.chain p {
  text-align: center;
  font-size: 14px;
  width: 94%;
  margin: 0 auto;
  margin-bottom: 9px;
}
.chain h5 {
  font-size: 23px;
  margin: 14px 0px 26px 5px;
}
.chain strong {
  font-size: 14px;
  display: block;
  text-align: center;
  word-break: break-word;
}
.chain .btn-close {
  background-color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}

/*--- banner ---*/


/*--- marquee ---*/
.bh_x {
  position: relative;
  margin-top: 7em;
}
@keyframes infiniteScroll {
  from {transform: translateX(0)}
  to {transform: translateX(-50%)}
}
.containe-x {
  width: 103%;
  overflow-x: hidden;
  background-color: #B30003;
  color: #fff;
  transform-box: border-box;
  transform-origin: left;
  transform: rotate(355deg);
  padding: 17px 0px 17px 0px;
  margin-left: -3px;
  position: relative;
  z-index: 1;
}
.horizontal-scrolling-items {
  display: flex;
  width: 2600px;
  animation-name: infiniteScroll;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.horizontal-scrolling-items.ewx {
  display: flex;
  width: 2600px;
  animation-name: infiniteScroll;
  animation-duration: 20s;
  animation-direction:reverse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.horizontal-scrolling-items__item {
  white-space: nowrap;
}
.containe-x2 {
  width: 103%;
  overflow-x: hidden;
  background-color: #3E8ECC;
  color: #fff;
  transform-box: border-box;
  transform-origin: left;
  transform: rotate(5deg);
  padding: 17px 0px 17px 0px;
  margin-left: -3px;
  position: relative;
  margin-top: -230px;
}
.horizontal-scrolling-items__item {
  white-space: nowrap;
  font-weight: 800;
  font-size: 23px;
  word-break: normal;
}
.horizontal-scrolling-items__item span {
  margin-left: 7px;
}
/*--- marquee ---*/


/*--- Sol-s ---*/
.Sol-s {
  padding: 100px 0;
  position: relative;
}
.small-logo img {
  width: 70px;
  height: 70px;
  margin-bottom: 37px;
}
.small-logo h4 {
  font-size: 30px;
  margin: 20px 0;
}
.small-logo ul {
  padding: 21px;
}
.small-logo ul li {
  list-style: auto;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 30px;
  word-wrap: break-word;
}
.bo {
  overflow: hidden;
}
.njxxxju {
  position: absolute;
  top: 0;
  width: 779px;
  z-index: -1;
  opacity: 0.8;
}
/*--- Sol-s ---*/

/*--- About ---*/
.about {
  padding: 100px 0;
  text-align: center;
}
.about h5 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 80px;
}
.bkss img {
  margin: 0 auto;
  height: 250px;
}
.bPxa  {
  padding: 20px;
}
.bPxa p {
  font-size: 17px;
  margin-top: -72px;
  transition: 0.5s;
  
}
.bPxa {
  padding: 0px 1px 3px 1px;
  height: 100%;
  border-top: 0;
  background-color: #fff;
}
.bkss {
  position: relative;
  bottom: 111px;
}
.worldx {
  height: 100%;
  background-color: #0e0c15;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: 0.5s;
  padding: 0px 27px 27px 27px;
}
.worldx:hover {
  background-color: #fff;
  transition: 0.5s;
}
.worldx:hover p {
  color: #000;
  transition: 0.5s;
}
.caqz {
  margin-top: 9em;
}
/*--- About ---*/

/*--- Fotter ---*/
.fp {
  border-top: 1px solid #fff;
  padding: 50px 0;
}
.fp p {
  text-align: center;
  width: 40%;
  margin: 0 auto;
  line-height: 28px;
}
.bpcs {
  background-color: #fff;
  padding: 14px 0;
}
.media ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
.media ul a {
  background-color: #000;
  color: #fff;
  display: flex;
  padding: 7px;
  border-radius: 8px;
}
.media ul a:hover {
  background-color: #b30003;
}
.bpcs p {
  margin: 0;
  text-align: end;
  font-size: 12px;
  color: #000;
}
/*--- Fotter ---*/


/*--- Media ---*/

@media screen and (min-width:1921px){
  .container-fluid{
    max-width: 1920px;
  }
}
@media screen and (max-width:1600px){
    .container-fluid {
        padding-left: 90px;
        padding-right: 91px;
    }
    .Anatomy {
      margin-top: 7em;
  }
    .Anatomy img {
      width: 61%;
  }
  .header .menu > ul > li > a {
    position: relative;
    font-size: 17px;
}
.wsdd ul li a {
  font-size: 17px;
}
.header {
  padding: 0.5rem 0;
}
.bux2 {
  top: 89px;
  width: 810px;
}
.bh_x {
  margin-top: 9em;
}
.horizontal-scrolling-items__item {
  font-size: 19px;
}
.containe-x {
  transform: rotate(352deg);
}
.small-logo img {
  width: 50px;
  height: 50px;
}
.small-logo h4 {
  font-size: 26px;
  margin: 12px 0;
}
.small-logo ul li {
  font-size: 17px;
  line-height: 32px;
  margin-bottom: 22px;
}
.about h5 {
  text-align: center;
  font-size: 30px;
}
.bkss img {
  height: 196px;
}
.bPxa p {
  font-size: 14px;
}
.fp p {
  font-size: 14px;
}
}


@media screen and (max-width:1300px){
  .container-fluid {
    padding-left: 56px;
    padding-right: 56px;
}
  .box-dc h1 {
    font-size: 24px;
    line-height: 36px;
    margin-top: 18px;
}
.meme-power h2 {
  font-size: 17px;
}
.box-dc h3 {
  font-size: 18px;
}
.nmc img {
  top: 12%;
}
.fox h4 {
  font-size: 16px;
}
.fox-x input {
  font-size: 19px;
}
.cop-x h4 {
  font-size: 16px;
}
.copy-text input.text {
  font-size: 14px;
}
.box-dc p {
  font-size: 13px;
}
.buy-x {
  padding: 14px;
  font-size: 17px;
}
.chain h5 {
  font-size: 20px;
  margin: 14px 0px 26px 5px;
}
.chain ul li a {
  font-size: 14px;
  padding: 9px 13px;
}
.chain p {
  font-size: 13px;
  width: 94%;
  margin-bottom: 9px;
}
.bpcs p {
  font-size: 11px;
}
.media ul a svg {
  width: 14px;
  height: 14px;
}
.fp p {
  font-size: 13px;
  width: 49%;
  line-height: 20px;
}
.bux2 {
  top: 89px;
  width: 651px;
}

}
@media screen and (max-width:1200px){
.container-fluid {
    padding-left: 40px;
    padding-right: 40px;
}
.meme-power {
  width: 100%;
}
}
@media screen and (max-width:1024px){
.container-fluid {
    padding-left: 36px;
    padding-right: 36px;
}
.social ul {
  margin: 0px 1.5rem;
}
.header .menu > ul > li > a {
  position: relative;
  font-size: 17px;
  border-bottom: 1px solid #404040;
}
.bux2 {
  top: 0;
  width: 506px;
}
.bux img {
  position: absolute;
  top: 89px;
  right: 0;
  z-index: -1;
  opacity: 0.8;
  width: 66%;
}
.box-dc {
  padding: 24px;
}
.box-dc h1 {
  font-size: 20px;
  line-height: 30px;
  margin-top: 18px;
}
.meme-power {
  padding: 14px;
  width: 89%;
  margin: 12px auto;
}
.meme-power h2 {
  font-size: 15px;
}
.box-dc h3 {
  font-size: 16px;
  margin: 15px 0;
}
.fox h4 {
  font-size: 15px;
}
.cop-x h4 {
  font-size: 15px;
}
.buy-x {
  padding: 12px;
  font-size: 16px;
}
.copy-text input.text {
  font-size: 12px;
}
.copy-text button {
  font-size: 13px;
  right: 5px;
  top: 3px;
}
.box-dc p {
  font-size: 12px;
}
.horizontal-scrolling-items__item {
  font-size: 17px;
}
.containe-x2 {
  transform: rotate(10deg);
  margin-top: -230px;
}
.containe-x {
  transform: rotate(351deg);
}
.header {
  padding: 0 0;
}
.bh_x {
  margin-top: 13em;
}
.small-logo h4 {
  font-size: 24px;
}
.small-logo ul li {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 17px;
}
.bkss img {
  height: 170px;
}
.worldx {
  padding: 0px 13px 18px 13px;
}
.bPxa p {
  font-size: 13px;
}
.fp {
  border-top: 1px solid #fff;
  padding: 35px 0;
}
.fp p {
  font-size: 12px;
}
.fp p {
  width: 62%;
  line-height: 26px;
}
.goog-te-gadget .goog-te-combo {
  right: 11px;
  width: 87px;
  padding: 11px 7px;
  border-radius: 6px;
}
}
@media screen and (max-width:992px){
.container {
  max-width: 870px;
}
.fox {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 89%;
  margin: 0 auto;
}
.containe-x {
  width: 101%;
  z-index: 1;
}
.containe-x2 {
  width: 101%;
  z-index: 1;
}
.containe-x {
  transform: rotate(353deg);
}
.containe-x2 {
  transform: rotate(9deg);
  margin-top: -182px;
}
.small-logo h4 {
  font-size: 22px;
}
.bkss img {
  height: 147px;
}
.about h5 {
  font-size: 27px;
  margin-bottom: 40px;
}
}
@media screen and (max-width:820px){
  .bPxa p {
    font-size: 12px;
}
.small-logo h4 {
  font-size: 20px;
}
.box-dc h1 {
  font-size: 17px;
}
.fox {
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}
.fp p {
  width: 62%;
  line-height: 19px;
}
.small-logo ul li {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 17px;
}
.meme-power {
  padding: 19px 7px;
  width: 100%;
  margin: 12px auto;
}
.buy-x {
  padding: 12px;
  font-size: 15px;
  width: 93%;
}
}

@media screen and (max-width:767px){
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}
.header {
  padding: 0 0;
}
.header-item-left .brand img {
  width: 51px;
}
.social ul li a {
  border-radius: 45px;
  width: 28px;
  height: 28px;
  line-height: 40px;
}
.social ul li a svg {
  width: 14px;
  height: 14px;
}
.about h5 {
  font-size: 24px;
  margin-bottom: 30px;
}
.wrapper {
  width: 100%;
}
.social ul {
  gap: 9px;
  margin: 0px 19px 1px 0;
}
.wsdd ul li a {
  font-size: 14px;
  padding: 7px 14px;
  display: none;
}
.goog-te-gadget .goog-te-combo {
  right: 0;
  width: 74px;
  padding: 5px 7px;
  border-radius: 6px;
  font-size: 12px;
}
.Anatomy img {
  width: 93%;
}
.meme-power {
  padding: 19px 3px;
}
.meme-power h2 {
  font-size: 14px;
}
.bux2 {
  top: 0;
  width: auto;
}
.fox-x input {
  font-size: 17px;
}
.nmc img {
  width: 31px;
  position: absolute;
  right: 7px;
  top: 11%;
}
.containe-x2 {
  transform: rotate(360deg);
  margin-top: 0;
}
.containe-x {
  transform: rotate(4deg);
}
.bh_x {
  margin-top: -1em;
}
.bo {
  margin-top: 2em;
}
.Sol-s {
  padding: 70px 0;
  position: relative;
}
.bkss {
  position: relative;
  bottom: 0;
}
.bPxa p {
  margin-top: 19px;
  transition: 0.5s;
  font-size: 13px;
}
.caqz {
  margin-top: 2em;
}
.row.caqz .px-0 {
  margin-bottom: 4em;
}
.about {
  padding: 100px 0 30px 0;
  text-align: center;
}
.bux img {
  top: 313px;
  width: 94%;
}
.fp {
  padding: 20px 0;
}
.fp p {
  width: 93%;
  line-height: 19px;
  font-size: 10px;
}
.chain h5 {
  font-size: 18px;
  margin: 9px 0px 21px 5px;
}
.chain strong {
  font-size: 13px;
}
.chain p {
  font-size: 12px;
  width: 100%;
  margin-bottom: 9px;
}
.bpcs p {
  font-size: 11px;
  text-align: center;
}
.media ul {
  justify-content: center;
  margin-bottom: 14px;
}

}








